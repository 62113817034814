<template>
    <v-main>
        <slot></slot>

        <v-overlay :model-value="loading" class="align-center justify-center">
            <v-progress-circular
                color="white"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-main>
</template>

<script>
import LookAndFeel from "~/helpers/LookAndFeel";

export default {
    name: "empty",
    mounted() {
        this.loading = true;
        LookAndFeel.load()
            .finally(() => {
                this.loading = false;
            });
    },
    data() {
        return {
            loading: false
        };
    }
};
</script>

<style scoped>

</style>